<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <div class="guide-page">
        <swiper :width="undefined" :roundLengths="true" :modules="modules" :pagination="true" @swiper="onSwiper1">
          <swiper-slide v-for="(item, i) in guideList" :key="i">
            <p class="imgText" v-html="item.text"></p>
            <img class="guide-img" :src="item.img" />
          </swiper-slide>
        </swiper>
        <ion-button v-if="swiper1 != null && swiper1.activeIndex == 4" class="guide-start-btn Bold" @click="startEvent()">Get started</ion-button>
        <div v-else class="guide-skip-btn" @click="skipEvent()">
          <span class="Medium">Skip</span>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton } from "@ionic/vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import inobounce from "inobounce";
import config from "../config";
export default {
  name: "userGuide",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, Swiper, SwiperSlide, IonButton },
  data() {
    return {
      guideList: [
        {
          text: "Exclusive offers and exciting rewards only for our members",
          img: require("@/assets/p01@3x.png"),
        },
        {
          text: "Support your favourite  club, school or charity",
          img: require("@/assets/p02@3x.png"),
        },
        {
          text: "Find hundreds of member only exclusive specials in-store <br /> each week",
          img: require("@/assets/p03@3x.png"),
        },
        {
          text: "Fast & convenient card access from your mobile wallet",
          img: require("@/assets/p04@3x.png"),
        },
        {
          text: "Find your nearest Ritchies store",
          img: require("@/assets/p05@3x.png"),
        },
      ],
      swiper1: null,
      modules: [Navigation, Pagination],
    };
  },
  methods: {
    onSwiper1(swiper) {
      this.swiper1 = swiper;
    },
    startEvent() {
      if (this.$route.query.isMore) {
        this.$router.go(-1);
      } else {
        this.$router.replace({
          path: "/main/me",
        });
      }
      this.sendGAEvent("Click", "UserGuide", "Get started");
    },
    skipEvent() {
      if (this.$route.query.isMore) {
        this.$router.go(-1);
      } else {
        this.$router.replace({
          path: "/main/landing",
        });
      }
      this.sendGAEvent("Click", "UserGuide", "Skip");
    },
  },
  ionViewDidEnter() {
    this.swiper1.activeIndex = 0;
  },
  async ionViewWillEnter() {
    localStorage.setItem("showUserGuide", true);
    if (this.$route.query.isMore) {
      this.$store.state.exitApp = false;
    } else {
      this.$store.state.exitApp = true;
    }
    this.sendGAPageView("User Guide");

    if (
      window.innerHeight > (window.innerWidth * 584) / 375 + 45 + 57 &&
      this.getDeviceType() == 1
    ) {
      inobounce.enable();
    } else {
      inobounce.disable();
    }
  },
  ionViewWillLeave() {
    inobounce.disable();
  },
};
</script>
<style scoped>
.swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.guide-page .swiper {
  overflow: inherit;
}
.guide-page :deep(.swiper-pagination) {
  bottom: -26px !important;
}
</style>
